import React from 'react';
import { Link } from 'react-router-dom';

const ServiceProp = ({ colSize, serviceStyle, serviceData }) => {

    return (
		<>
			{serviceData.map((data, index) => (
				<div className={colSize} key={index} >
					<div className={`services-grid ${serviceStyle}`}>
						<div className="thumbnail">
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
						</div>
						<div className="content">
							<h5 className="title"> 
								{data.url ? <Link to={data.url}>{data.title}</Link> : data.title}
							</h5>
							<p>{data.description}</p>
							{data.url ? <Link to={data.url} className="more-btn">Find out more</Link> : null}
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default ServiceProp;