import { routes } from '../../utils/routes';

const splashData = [
  [
    {
      id: 1,
      width_img: '/images/others/demo-slide-1.png',
      height_img: '/images/others/demo-1.png',
      title: 'Digital Agency',
      link: routes.animation,
    },
    {
      id: 2,
      width_img: '/images/others/demo-slide-2.png',
      height_img: '/images/others/demo-2.png',
      title: 'Software Development',
      link: routes.softwareDevelopment,
    },
    {
      id: 3,
      width_img: '/images/others/demo-slide-3.png',
      height_img: '/images/others/demo-3.png',
      title: 'Design and Branding',
      link: routes.designAndBranding,
    },
    {
      id: 4,
      width_img: '/images/others/demo-slide-4.png',
      height_img: '/images/others/demo-4.png',
      title: 'Marketing',
      link: routes.marketing,
    },
    // {
    //   id: 5,
    //   width_img: '/images/others/demo-slide-5.png',
    //   height_img: '/images/others/demo-5.png',
    //   title: 'Marketing',
    //   link: routes.digitalAgency,
    // }
  ],
  [
    {
      id: 1,
      icon: '/images/icon/react.png',
      title: 'Custom Software <br />Solutions',
      para: 'We pride ourselves on creating bespoke software solutions that meet the unique needs of our clients. Our team of experienced developers will work closely with you to develop tailored solutions that fit your specific business requirements'
    },
    {
      id: 2,
      icon: '/images/icon/code-2.png',
      title: 'Mobile App Development',
      para: 'Our team has expertise in developing mobile applications for both iOS and Android platforms. We can help you create apps that offer engaging user experiences and drive business growth'
    },
    {
      id: 3,
      icon: '/images/icon/sass.png',
      title: 'Web Development',
      para: 'Our web development services leverage modern web technologies to create fast, responsive, and user-friendly websites that help businesses establish a strong online presence'
    },
    {
      id: 4,
      icon: '/images/icon/responsive.png',
      title: 'UX/UI Design',
      para: 'Our team of experienced designers can help you create visually stunning and intuitive interfaces for your software products that optimize user engagement and drive conversions'
    },
    {
      id: 5,
      icon: '/images/icon/loading.png',
      title: 'E-commerce Solutions',
      para: 'We offer a range of e-commerce solutions that are tailored to your business needs. Our solutions are secure and easy to use, and designed to help you sell more products online'
    },
    {
      id: 6,
      icon: '/images/icon/hart-rate.png',
      title: 'Motion Graphics and Animation',
      para: 'Our team of experienced animators can create engaging motion graphics and animations that help you tell your brand story in a compelling way. Whether you need a short video for social media or an animated explainer video for your website, we\'ve got you covered'
    },
    {
      id: 7,
      icon: '/images/icon/icon-17.png',
      title: 'Quality Assurance <br />and Testing',
      para: 'Our dedicated QA and testing team ensures that all software products we develop are of the highest quality and stability, helping you achieve a seamless user experience'
    },
    {
      id: 9,
      icon: '/images/icon/icon-21.png',
      title: 'Photography',
      para: 'Quasar offers professional photography services, including product photography, corporate headshots, and event photography. We can help you capture high-quality images that reflect your brand and showcase your products and services'
    },
    {
      id: 8,
      icon: '/images/icon/icon-20.png',
      title: 'Ongoing Support and Maintenance',
      para: 'We offer ongoing support and maintenance to ensure that your software products continue to function optimally over time, helping you achieve your business objectives'
    },
    {
      id: 11,
      icon: '/images/icon/hart-rate.png',
      title: 'Video Editing',
      para: 'We offer professional video editing services that help you turn raw footage into polished, professional videos that engage and inform your audience. Our editors can help you with everything from simple cuts and transitions to more complex editing tasks, such as color grading and special effects'
    },
    {
      id: 10,
      icon: '/images/icon/icon-13.png',
      title: 'Integration with <br />Third-party Services',
      para: 'We understand the importance of integration with third-party services and can help you seamlessly integrate your software products with payment gateways, social media platforms, analytics tools, and more'
    },
    {
      id: 12,
      icon: '/images/icon/icon-24.png',
      title: '3D Modeling and Rendering',
      para: 'Our team can help you create high-quality 3D models and renderings that bring your products and services to life. Whether you need 3D models for product design, architectural visualization, or gaming, we have the expertise and tools to help you achieve your goals'
    }
  ]
];

export default splashData;