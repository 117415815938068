import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BcrumbBannerOne from '../../elements/breadcrumb/BcrumbBannerOne';
import SectionTitle from '../../quasarComponents/section-title/SectionTitle';
import CtaLayoutOne from '../../component/cta/CtaLayoutOne';
import { subServices } from '../../data/quasar/services';
import Process from './process';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';

const ServiceDetails = () => {
    const params = useParams();
    const [serviceData, setServiceData] = useState(subServices.find(data => data.serviceKey === params.service));

    useEffect(() => {
        const newServiceData = subServices.find(data => data.serviceKey === params.service);
        
        if (!newServiceData) {
            window.location.replace('/404');
        }
        setServiceData(newServiceData);
    }, [params]);

    if (!serviceData) {
        return null;
    }

    return (
        <>
        <SEO title={serviceData.seoTitle} />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title={serviceData.title}
                paragraph ={serviceData.description}
                styleClass=""
                mainThumb={serviceData.headerImage}
            />
            <SectionTitle subtitle={serviceData.process.subtitle} title={serviceData.process.title} description={serviceData.process.description}/>
            {!!serviceData.process && <Process data={serviceData.process} />}
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceDetails;