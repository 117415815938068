export const routes = {
  index: '/',
  softwareDevelopment: '/software-development',
  softwareDevelopmentServices: '/software-development/:service',
  animation: '/3d-animation-and-vfx',
  animationServices: '/3d-animation-and-vfx/:service',
  designAndBranding: '/design-and-branding',
  designAndBrandingServices: '/design-and-branding/:service',
  marketing: '/marketing',
  marketingServices: '/marketing/:service',
  
  ceroteto: process.env.PUBLIC_URL + '/ceroteto',
  
  digitalAgency: process.env.PUBLIC_URL + '/digital-agency',
  creativeAgency: process.env.PUBLIC_URL + '/creative-agency',
  personalPortfolio: process.env.PUBLIC_URL + '/personal-portfolio',
  homeStartup: process.env.PUBLIC_URL + '/home-startup',
  corporateAgency: process.env.PUBLIC_URL + '/corporate-agency',
  blogGrid: process.env.PUBLIC_URL + '/blog-grid',
  category: process.env.PUBLIC_URL + '/category/:slug',
  archive: process.env.PUBLIC_URL + '/archive/:slug',
  blogDetails: process.env.PUBLIC_URL + '/blog-details/:id',
  serviceOne: process.env.PUBLIC_URL + '/service-one',
  serviceTwo: process.env.PUBLIC_URL + '/service-two',
  serviceDetails: process.env.PUBLIC_URL + '/service-details/:slug',
  projectGridOne: process.env.PUBLIC_URL + '/project-grid-one',
  projectGridTwo: process.env.PUBLIC_URL + '/project-grid-two',
  projectGridThree: process.env.PUBLIC_URL + '/project-grid-three',
  projectWidthOne: process.env.PUBLIC_URL + '/project-width-one',
  projectWidthTwo: process.env.PUBLIC_URL + '/project-width-two',
  projectDetails: process.env.PUBLIC_URL + '/project-details/:slug',
  aboutUs: process.env.PUBLIC_URL + '/about-us',
  ourOffice: process.env.PUBLIC_URL + '/our-office',
  ourClients: process.env.PUBLIC_URL + '/our-clients',
  team: process.env.PUBLIC_URL + '/team',
  teamDetails: process.env.PUBLIC_URL + '/team-details/:slug',
  caseStudy: process.env.PUBLIC_URL + '/case-study',
  caseDetails: process.env.PUBLIC_URL + '/case-details/:slug',
  testimonials: process.env.PUBLIC_URL + '/testimonials',
  pricingTable: process.env.PUBLIC_URL + '/pricing-table',
  typography: process.env.PUBLIC_URL + '/typography',
  contact: process.env.PUBLIC_URL + '/contact',
  notFound: process.env.PUBLIC_URL + '/404',
  comingSoon: process.env.PUBLIC_URL + '/coming-soon',
  privacyPolicy: process.env.PUBLIC_URL + '/privacy-policy',
  termsUse: process.env.PUBLIC_URL + '/terms-use',
};