import { routes } from '../../utils/routes'

export const services = [
    {
        seoTitle: 'Software Development Services',
        mainThumb: "/images/quasar/services/vfx/banner-thumb-8.png",
        title: "Software Development",
        description: "Turning ideas into digital reality. Our Software Development service crafts custom solutions, from mobile apps to web platforms, tailored to your unique needs.",
        servicesTitle: 'Software Development Solutions',
        category: 'Development',
        path: routes.softwareDevelopment,
    },
    {
        seoTitle: '3D Animation and VFX Services',
        mainThumb: "/images/quasar/services/vfx/3dbanner.png",
        title: "3D Animation and VFX",
        description: "Turning ideas into digital reality. Our 3D Animation and VFX service crafts custom solutions, from mobile apps to web platforms, tailored to your unique needs.",
        servicesTitle: '3D Animation and VFX Solutions',
        category: '3d',
        path: routes.animation,
    },
    {
        seoTitle: 'Design and Branding Services',
        mainThumb: '/images/quasar/services/design/banner_branding.png',
        title: "Design & Branding",
        description: "Turning ideas into digital reality. Our Design and Branding service crafts custom solutions, from mobile apps to web platforms, tailored to your unique needs.",
        servicesTitle: 'Design and Branding Solutions',
        category: 'design',
        path: routes.designAndBranding,
    },
    {
        seoTitle: 'Marketing Services',
        mainThumb: '/images/quasar/services/software-development/outsourcing/banner-thumb-1.png',
        title: "Marketing",
        description: "Turning ideas into digital reality. Our Marketing service crafts custom solutions, from mobile apps to web platforms, tailored to your unique needs.",
        servicesTitle: 'Marketing Solutions',
        category: 'marketing',
        path: routes.marketing,
    },
];

export const subServices = [
  // Software development
  {
    image: '/images/quasar/services/software-development/web-app/banner-webapp-icon.png',
    title: 'Web-App Design & Development',
    description: 'Creating seamless web applications that engage and empower. Our Web-App Design & Development brings your digital vision to life',
    url: routes.softwareDevelopment + '/web-design-and-development',
    seoTitle: 'Service Details',
    category: 'Development',
    headerImage: '/images/quasar/services/software-development/web-app/banner-webapp.png',
    serviceKey: 'web-design-and-development',
    process: {
      subtitle: "",
      title: 'Software Development',
      description: 'In a digital world where the user experience is paramount, our Web-App Design and Development service promises not just an application, but a digital experience that resonates, engages, and delivers results. Partner with us to transform your vision into an interactive reality that captivates your audience and propels your business forward',
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/software-development/web-app/process-1.png',
              subtitle: "",
              title: 'Crafting Digital Experiences That Shine',
              paragraph: "In today's digitally-driven landscape, a compelling web application can be the cornerstone of your business's online presence. Our Web-App Design and Development service is your pathway to creating dynamic, user-centric, and impactful digital solutions that engage visitors and drive business growth."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/software-development/web-app/process-2.png',
              subtitle: "",
              title: 'Designing Experiences That Resonate',
              paragraph: "Our design process goes beyond aesthetics - it's about creating experiences that resonate with your target audience. Our skilled UI/UX designers meticulously craft user journeys that are intuitive, visually pleasing, and result-driven. We believe in the power of user-centric design, ensuring that every click and interaction aligns with your users' expectations, ultimately boosting engagement and conversions."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/software-development/web-app/process-3.png',
              subtitle: "",
              title: 'Development That Empowers',
              paragraph: "Behind every stunning web application is a robust technological foundation. Our developers are adept at turning design concepts into functional reality. Whether it's a content management system (CMS), an e-commerce platform, or a complex data-driven application, our development team employs the latest technologies and best practices to build responsive, scalable, and secure web applications. Our coding prowess extends to frontend and backend development, ensuring a seamless user experience and efficient data management."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/software-development/web-app/process-4.png',
              subtitle: "",
              title: 'Strategic Innovation',
              paragraph: "We don't just build web apps; we engineer solutions that align with your business objectives. From ideation to deployment, our process is collaborative. We work closely with you to understand your unique needs, challenges, and aspirations. Our team's commitment to staying updated with emerging technologies enables us to integrate innovative features that give your web app a competitive edge. Whether it's real-time interactivity, integration with third-party APIs, or scalability to accommodate growing user bases, our solutions are tailored to meet your evolving requirements."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/software-development/email/banner-webmail-icon.png',
    title: 'Email templates & Marketing',
    description: 'Powerful email solutions. We design templates and execute campaigns that captivate, connect, and convert. Elevate your marketing game with us.',
    url: routes.softwareDevelopment + '/email-templates-and-marketing',
    seoTitle: 'Service Details',
    category: 'Development',
    headerImage: '/images/quasar/services/software-development/email/banner-webmail.png',
    serviceKey: 'email-templates-and-marketing',
    process: {
      subtitle: "",
      title: 'Email & Marketing',
      description: "Elevate your brand's digital communication with our Email Templates & Marketing service. From visually stunning templates to strategically crafted campaigns, we're your partner in transforming emails from mere communication to impactful connections that drive business growth.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/software-development/email/process-1.png',
              subtitle: "",
              title: 'Amplify Your Message, Maximize Impact',
              paragraph: "In the digital age, effective communication is pivotal for business success. Our Email Templates & Marketing service is designed to help you harness the potential of email campaigns to connect with your audience, foster engagement, and drive results."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/software-development/email/process-2.png',
              subtitle: "",
              title: 'Strategic Design for Lasting Impressions',
              paragraph: "Emails are not just text; they're an extension of your brand identity. Our expert designers create visually captivating email templates that resonate with your brand's essence. Each template is meticulously crafted to capture attention, convey your message effectively, and encourage action. Whether it's a promotional offer, a newsletter, or an announcement, our templates ensure your communication stands out in crowded inboxes."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/software-development/email/process-3.png',
              subtitle: "",
              title: 'Engagement-Driven Campaigns',
              paragraph: "Sending an email is one thing, but delivering a compelling story that prompts engagement is where the true power lies. Our Email Templates & Marketing service extends beyond design – our marketing strategists understand your audience, tailoring messages that resonate. We segment and personalize campaigns, ensuring that the right content reaches the right recipients at the right time. This personalized approach fosters engagement and builds a loyal customer base."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/software-development/email/process-4.png',
              subtitle: "",
              title: 'Analytics-Backed Results',
              paragraph: "The key to improving any strategy is data-driven insights. We provide comprehensive analytics for each campaign, offering a clear view of open rates, click-through rates, and conversions. This data empowers you to refine your approach continuously, optimizing future campaigns for even greater impact."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/software-development/web-app/banner-webappdevicon.png',
    title: 'Mobile App Development',
    description: 'Transforming ideas into seamless mobile experiences. Our Mobile App Development service brings innovation to your fingertips.',
    url: routes.softwareDevelopment + '/mobile-apps',
    seoTitle: 'Service Details',
    category: 'Development',
    headerImage: '/images/quasar/services/software-development/web-app/banner-webappdev.png',
    serviceKey: 'mobile-apps',
    process: {
      subtitle: "",
      title: 'Mobile Apps',
      description: "With our Mobile App Development service, we're not just building apps; we're creating experiences. From concept to execution and beyond, we're your partner in crafting apps that leave lasting impressions and drive your business forward in the mobile-first era.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/software-development/mobile/process-1.png',
              subtitle: "",
              title: 'Turning Ideas Into Intuitive Experiences',
              paragraph: "In a world driven by mobile interactions, having a powerful mobile app is no longer a luxury but a necessity. Our Mobile App Development service is the pathway to transforming your unique idea into a tangible and user-friendly application that resonates with your target audience."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/software-development/mobile/process-2.png',
              subtitle: "",
              title: 'Concept to Reality',
              paragraph: "Your vision is where it all starts. Our skilled team of developers and designers collaborate closely with you to understand your concept, business goals, and user expectations. We then harness the latest technologies to breathe life into your idea, crafting an app that not only meets your vision but also exceeds industry standards."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/software-development/mobile/process-3.png',
              subtitle: "",
              title: 'User-Centric Design',
              paragraph: "A captivating app not only functions flawlessly but also delights users with its design. Our UI/UX designers specialize in creating intuitive interfaces that enhance user experiences. We meticulously design every button, animation, and navigation element to ensure users enjoy seamless interactions, resulting in higher engagement rates and user satisfaction."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/software-development/mobile/process-4.png',
              subtitle: "",
              title: 'Platform Diversity',
              paragraph: "The mobile landscape is diverse, and so should your app be. Whether it's iOS, Android, or both, we've got you covered. Our developers are well-versed in the intricacies of each platform, ensuring your app is optimized for performance and user experience across various devices."
          },
          {
              id: 5,
              thumb: '/images/quasar/services/software-development/mobile/process-5.png',
              subtitle: "",
              title: 'Constant Evolution',
              paragraph: "The digital world is ever-evolving, and so should your app. Our commitment doesn’t end with the launch – we provide ongoing maintenance, updates, and optimization to keep your app at its best. Whether it's new features, security updates, or platform compatibility enhancements, we ensure your app evolves with the times."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/software-development/support/banner-suppicon.png',
    title: 'Development support',
    description: 'Empowering your digital journey. Our Development Support service ensures your solutions run flawlessly, evolve with innovation, and stay ahead in the tech game.',
    url: routes.softwareDevelopment + '/development-support',
    seoTitle: 'Service Details',
    category: 'Development',
    headerImage: '/images/quasar/services/software-development/support/banner-supp.png',
    serviceKey: 'development-support',
    process: {
      subtitle: "",
      title: 'Development Support',
      description: "With our Development Support service, we're not just your technical support; we're your strategic partner in navigating the ever-evolving technology landscape. Let us take care of the technicalities, allowing you to focus on what you do best – driving your business forward.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/software-development/support/process-1.png',
              subtitle: "",
              title: 'Your Partner in Technical Excellence',
              paragraph: "In the dynamic world of technology, continuous support is the backbone of success. Our Development Support service is designed to be your steadfast partner in maintaining, optimizing, and evolving your digital solutions, ensuring they remain at the forefront of innovation."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/software-development/support/process-2.png',
              subtitle: "",
              title: 'Ongoing Maintenance and Updates',
              paragraph: "Technology never stands still, and neither should your digital solutions. Our Development Support service offers regular maintenance and updates to keep your software running smoothly. From bug fixes to security patches, we ensure your applications are up to date, secure, and capable of delivering seamless experiences."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/software-development/support/process-3.png',
              subtitle: "",
              title: 'Performance Optimization',
              paragraph: "In the digital realm, a fraction of a second can make a world of difference. Our team constantly monitors and optimizes your software's performance, ensuring quick load times and smooth interactions. This dedication to speed and efficiency enhances user experiences and fosters customer satisfaction."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/software-development/support/process-4.png',
              subtitle: "",
              title: 'Scalability and Growth',
              paragraph: "As your business evolves, your digital solutions should too. Our Development Support service is equipped to scale your software, accommodating increased user traffic and data requirements. Whether it's expanding your e-commerce platform or enhancing your app's capabilities, we're here to ensure your technology grows with you."
          },
          {
              id: 5,
              thumb: '/images/quasar/services/software-development/support/process-5.png',
              subtitle: "",
              title: 'Innovative Upgrades',
              paragraph: "Stagnation is the enemy of progress. Our Development Support service keeps your software solution aligned with emerging technologies and industry trends. We analyze the potential of new features, integrations, and enhancements that can elevate your digital presence, ensuring you stay ahead in a competitive landscape."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/software-development/outsourcing/icon-42.png',
    title: 'Outsourcing Services',
    description: "Unlock expertise on demand. Our Outsourcing Solutions seamlessly extend your team's capabilities, ensuring cost-effective efficiency and strategic collaboration.",
    url: routes.softwareDevelopment + '/outsourcing',
    seoTitle: 'Service Details',
    category: 'Development',
    headerImage: '/images/quasar/services/software-development/outsourcing/banner-thumb-1.png',
    serviceKey: 'outsourcing',
    process: {
      subtitle: "",
      title: 'Outsourcing Service',
      description: "With our Outsourcing Solutions, you're not just hiring a service; you're gaining a strategic partner. We amplify your capabilities, enhance your efficiency, and allow you to stay competitive in a fast-paced business landscape. Let us handle the intricacies, while you focus on what truly matters – propelling your business to greater heights.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/software-development/outsourcing/process-1.png',
              subtitle: "",
              title: 'Extending Your Team, Amplifying Success',
              paragraph: "In a globalized business landscape, tapping into specialized skills while maintaining cost-efficiency is essential. Our Outsourcing Solutions service is designed to be your strategic partner, offering a skilled extension to your team that enables you to focus on your core business while we handle the rest."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/software-development/outsourcing/process-2.png',
              subtitle: "",
              title: 'Expertise at Your Fingertips',
              paragraph: "Our Outsourcing Solutions provide access to a diverse range of experts across various fields. From software development to design, marketing, and beyond, we offer dedicated professionals with proven track records in their domains. This allows you to harness expertise tailored to your needs without the overheads of maintaining an in-house team."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/software-development/outsourcing/process-3.png',
              subtitle: "",
              title: 'Cost-Effective Scalability',
              paragraph: "As your projects ebb and flow, so should your resources. Outsourcing allows you to scale up or down swiftly based on project requirements, saving you the costs and time associated with recruiting, training, and onboarding. This flexibility ensures that your business remains nimble and efficient in a rapidly changing market."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/software-development/outsourcing/process-4.png',
              subtitle: "",
              title: 'Streamlined Efficiency',
              paragraph: "Our Outsourcing Solutions focus on streamlining operations. By delegating non-core tasks to us, your core team can concentrate on strategic initiatives and value-added activities that directly impact your business growth. This division of labor enhances overall efficiency and helps your organization achieve more in less time."
          },
          {
              id: 5,
              thumb: '/images/quasar/services/software-development/outsourcing/process-5.png',
              subtitle: "",
              title: 'Collaboration without Boundaries',
              paragraph: "Our approach to outsourcing isn't just transactional; it's collaborative. We work closely with you, aligning our efforts with your goals and culture. Communication tools and technologies bridge geographical gaps, allowing us to seamlessly integrate with your processes and keep you in the loop every step of the way."
          },
      ],
    }
  },
  // VFX
  {
    image: '/images/quasar/services/vfx/3d-modeling/3d-banner-icon.png',
    title: '3D Modeling',
    description: 'Breathing life into concepts, our 3D Modeling service turns imagination into intricate virtual reality',
    url: routes.animation + '/3d-modeling',
    seoTitle: 'Service Details',
    category: '3d',
    headerImage: '/images/quasar/services/vfx/3d-modeling/3d-banner.png',
    serviceKey: '3d-modeling',
    process: {
      subtitle: "",
      title: '3D Modeling',
      description: 'With our 3D Modeling service, we invite you to venture beyond the confines of two dimensions. Immerse your audience in the virtual world of your ideas, and let our meticulous artistry transform your visions into awe-inspiring realities that engage, educate, and inspire.',
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/vfx/3d-modeling/process-1.png',
              subtitle: "",
              title: 'Transforming Concepts into Virtual Reality',
              paragraph: "In the realm of modern visual communication, 3D modeling stands as a pivotal bridge between imagination and reality. Our 3D Modeling service is a creative journey where ideas and concepts materialize into tangible virtual objects, ready to captivate audiences and elevate visual experiences."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/vfx/3d-modeling/process-2.png',
              subtitle: "",
              title: 'Precision Craftsmanship',
              paragraph: "Our team of skilled 3D artists employs advanced techniques to meticulously craft every facet of your vision. From architectural marvels to intricate product prototypes, we meticulously shape every curve, texture, and dimension. This precision craftsmanship ensures that your concept is transformed into a lifelike digital masterpiece, offering viewers a detailed insight that static images simply cannot convey."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/vfx/3d-modeling/process-3.png',
              subtitle: "",
              title: 'Visualizing the Unseen',
              paragraph: "3D modeling grants the power to visualize the unseen. Our service goes beyond representation; it enables the exploration of intricate details, hidden angles, and conceptual depths. Whether you're visualizing an innovative product design, a future architectural marvel, or a character for animation, our 3D models provide a window into the yet-to-be-realized."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/vfx/3d-modeling/process-4.png',
              subtitle: "",
              title: 'Versatility and Innovation',
              paragraph: "The canvas of 3D modeling knows no bounds. From intricate machinery components to fantastical creatures, we embrace versatility with every project. Our commitment to innovation ensures that we remain at the forefront of industry trends and technologies, resulting in 3D models that not only capture reality but also inspire innovation."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/vfx/character-animation/banner-character-icon.png',
    title: 'Character Animation',
    description: 'Animating emotions, our Character Animation service gives life to digital personas, crafting stories that resonate.',
    url: routes.animation + '/character-animation',
    seoTitle: 'Service Details',
    category: '3d',
    headerImage: '/images/quasar/services/vfx/character-animation/banner-character.png',
    serviceKey: 'character-animation',
    process: {
      subtitle: "",
      title: 'Character Animation',
      description: "With our Character Animation service, we transcend pixels, transforming digital entities into relatable personas that elicit emotions and captivate audiences. Whether for entertainment, education, or brand promotion, we're dedicated to breathing life into your characters, creating animations that tell compelling stories and leave lasting impressions.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/vfx/character-animation/banner-character.png',
              subtitle: "",
              title: 'Emotive Storytelling',
              paragraph: "Characters are the heart of storytelling, and our Character Animation service gives them a voice. With meticulous attention to detail, we craft movements, expressions, and interactions that mirror human emotions. Whether it's for entertainment, education, or brand communication, we ensure characters become relatable, engaging, and memorable protagonists in your visual narratives."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/vfx/character-animation/process-1.png',
              subtitle: "",
              title: 'Seamless Interaction',
              paragraph: "Character interactions add depth to narratives. Our skilled animators orchestrate seamless interactions between characters and their environments. From a casual wave to complex actions, our animations maintain fluidity and authenticity, enhancing the immersive experience and evoking emotional connections."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/vfx/character-animation/process-1.png',
              subtitle: "",
              title: 'Dynamic Realism',
              paragraph: "Dynamic realism is our hallmark. We breathe life into characters through advanced techniques, ensuring their actions are grounded in reality. Whether it's the physics of movement, facial expressions, or subtle gestures, our animations resonate with authenticity, captivating audiences and conveying messages effectively."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/vfx/character-animation/process-1.png',
              subtitle: "",
              title: 'Versatility in Style',
              paragraph: "Every story demands a unique visual language. Our Character Animation service embraces versatility, adapting to various styles – from cartoonish charm to hyper-realism. We tailor animations to your project's needs, ensuring characters not only drive narratives but also become iconic representations of your brand or vision"
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/vfx/props/banner-props-icon.png',
    title: 'Props and Scenes Creation',
    description: 'Crafting immersive environments, our Props and Scenes Creation service brings narratives to life through meticulously designed elements that enhance visual storytelling',
    url: routes.animation + '/props-and-scenes-creation',
    seoTitle: 'Service Details',
    category: '3d',
    headerImage: '/images/quasar/services/vfx/props/banner-props.png',
    serviceKey: 'props-and-scenes-creation',
    process: {
      subtitle: "",
      title: 'Props and Scenes Creation',
      description: "With our Props and Scenes Creation service, we don't just design environments; we craft immersive worlds that amplify your storytelling potential. From meticulous detailing to atmospheric mastery, we're dedicated to creating scenes that transport your audience into the heart of your narratives, enriching their experiences and leaving a lasting impact.",
      sections: [
          {
              id: 1,
              thumb: '/images/quasar/services/vfx/props/process-1.png',
              subtitle: "",
              title: 'Setting the Stage',
              paragraph: "Every story unfolds within an environment. Our Props and Scenes Creation service establishes the stage for your narratives. From sprawling landscapes to intricate interiors, we meticulously design backgrounds that amplify the mood and context of your story, providing an immersive canvas for your characters and concepts."
          },
          {
              id: 2,
              thumb: '/images/quasar/services/vfx/props/process-1.png',
              subtitle: "",
              title: 'Authentic Detailing',
              paragraph: "Authenticity lies in the details. Our skilled artists meticulously craft props and elements that add depth and realism to your scenes. Whether it's historical accuracy or futuristic visions, we ensure every element is true to the narrative, resonating with audiences and enhancing their engagement."
          },
          {
              id: 3,
              thumb: '/images/quasar/services/vfx/props/process-1.png',
              subtitle: "",
              title: 'Atmosphere and Mood',
              paragraph: "Authenticity lies in the details. Our skilled artists meticulously craft props and elements that add depth and realism to your scenes. Whether it's historical accuracy or futuristic visions, we ensure every element is true to the narrative, resonating with audiences and enhancing their engagement."
          },
          {
              id: 4,
              thumb: '/images/quasar/services/vfx/props/process-1.png',
              subtitle: "",
              title: 'Seamless Integration',
              paragraph: "The magic lies in seamless integration. Our Scenes Creation service ensures that characters and props harmonize perfectly within the environment. We pay meticulous attention to scale, perspective, and lighting, resulting in scenes where every element feels naturally connected, further immersing viewers into your narrative."
          },
      ],
    }
  },
  {
    image: '/images/quasar/services/vfx/vfx/banner-flame-icon.png',
    title: "VFX",
    description: "Unleashing the extraordinary, our VFX service transforms visuals, adding stunning effects that captivate and elevate the impact of your content.",
    url: routes.animation + "/vfx",
    seoTitle: "Service Details",
    category: "3d",
    headerImage: '/images/quasar/services/vfx/vfx/banner-flame.png',
    serviceKey: "vfx",
    process: {
      subtitle: "",
      title: "VFX (Visual Effects)",
      description: "With our VFX service, we're not just adding effects; we're amplifying emotions and creating moments of awe. Whether for films, commercials, or any visual content, our VFX experts craft visuals that resonate, leaving audiences spellbound and transforming ordinary scenes into cinematic marvels.",
      sections: [
          {
              id: 1,
              thumb: "/images/quasar/services/vfx/vfx/process-1.png",
              subtitle: "",
              title: "Cinematic Spectacles",
              paragraph: "VFX is where reality and imagination collide. Our VFX service specializes in crafting cinematic spectacles that push the boundaries of visual storytelling. From awe-inspiring explosions to enchanting magical effects, we amplify your narratives with jaw-dropping visuals that resonate with audiences."
          },
          {
              id: 2,
              thumb: "/images/quasar/services/vfx/vfx/process-1.png",
              subtitle: "",
              title: "Seamless Integration",
              paragraph: "Seamlessness is key in VFX. Our skilled technicians seamlessly integrate these effects into your content, maintaining a cohesive flow that doesn't distract from the story. Whether it's merging real actors with fantastical creatures or weaving intricate simulations, our VFX expertise ensures that the extraordinary feels authentic."
          },
          {
              id: 3,
              thumb: "/images/quasar/services/vfx/vfx/process-1.png",
              subtitle: "",
              title: "Dynamic Realism",
              paragraph: "Realism is at the heart of our VFX approach. We meticulously analyze the physics of each effect, ensuring movements, lighting, and interactions align with the laws of reality. This dynamic realism elevates the impact, making the extraordinary feel like an organic part of your narrative."
          },
          {
              id: 4,
              thumb: "/images/quasar/services/vfx/vfx/process-1.png",
              subtitle: "",
              title: "Creative Innovation",
              paragraph: "Creativity knows no bounds in VFX. Our service thrives on innovative solutions that bring imagination to life. Whether it's reshaping environments, altering weather, or conjuring otherworldly phenomena, we're committed to pushing the envelope, creating effects that are as unique as your narrative itself."
          },
          {
              id: 4,
              thumb: "/images/quasar/services/vfx/vfx/process-1.png",
              subtitle: "",
              title: "Seamless Integration",
              paragraph: ""
          },
      ],
    }
  },
  // DESIGN
  {
    image: "/images/quasar/services/design/branding/icon-40.png",
    title: "Logo and Branding Design",
    description: "Crafting identities that stand out, our Logo and Branding Design service brings your vision to life through distinctive visual elements that leave a lasting impression.",
    url: routes.designAndBranding + "/branding",
    seoTitle: "Service Details",
    category: "design",
    headerImage: "/images/quasar/services/design/branding/banner-thumb-1.png",
    serviceKey: "branding",
    process: {
      subtitle: "",
      title: "Logo and Branding Design",
      description: "With our Logo and Branding Design service, we're not just creating visuals; we're crafting your brand's story. From the first impression to lasting engagement, our designs make your identity stand out in a crowded market, forging connections and leaving an indelible mark on your audience's mind.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/design/branding/process-1.png",
            subtitle: "",
            title: "Identity Forged in Design",
            paragraph: "A logo is the cornerstone of your brand's identity. Our Logo and Branding Design service begins with understanding your essence and values. We then meticulously craft logos that encapsulate your story, enabling instant recognition and establishing a strong foundation for your brand's identity."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/design/branding/process-1.png",
            subtitle: "",
            title: "Visual Language Extension",
            paragraph: "Branding is beyond a logo; it's a visual language that resonates with your audience. Our service extends to crafting a consistent visual identity – from color palettes and typography to imagery styles. This comprehensive approach ensures that your brand's personality is echoed across all touchpoints, fostering trust and recognition."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/design/branding/process-1.png",
            subtitle: "",
            title: "Cohesive Experience",
            paragraph: "Our branding expertise ensures a cohesive experience. Whether it's packaging, marketing materials, or online presence, every element is harmonized to reinforce your brand's essence. This consistency amplifies brand recall and creates a sense of familiarity that connects deeply with your target audience."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/design/branding/process-1.png",
            subtitle: "",
            title: "Enduring Impressions",
            paragraph: "We design with the future in mind. Our Logo and Branding Design service aims for timeless aesthetics that remain relevant as your brand evolves. We consider versatility – how your logo translates across platforms, from business cards to billboards – ensuring it retains impact in various contexts."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/design/packaging/icon-40.png",
    title: "Packaging Design",
    description: "Elevating products through visual storytelling, our Packaging Design service creates captivating and functional packaging that resonates with your target audience.",
    url: routes.designAndBranding + "/packaging",
    seoTitle: "Service Details",
    category: "design",
    headerImage: "/images/quasar/services/design/packaging/banner-thumb-1.png",
    serviceKey: "packaging",
    process: {
      subtitle: "",
      title: "Packaging Design",
      description: "With our Packaging Design service, we're not just wrapping products; we're telling stories. From design conceptualization to the final creation, our packaging designs evoke emotions, intrigue, and trust, setting the stage for a memorable and positive consumer experience.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/design/packaging/process-1.png",
            subtitle: "",
            title: "The Art of First Impressions",
            paragraph: "Packaging is the first touchpoint with your product. Our Packaging Design service understands the power of that first impression. We craft designs that not only protect and present your product but also capture attention on shelves, communicating its essence and encouraging a connection."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/design/packaging/process-1.png",
            subtitle: "",
            title: "Form and Function Fusion",
            paragraph: "Design isn't just about aesthetics; it's about usability. Our service merges form and function seamlessly. From ergonomic structures to intuitive openings, we ensure that your packaging design not only entices but also enhances the user experience, creating a positive association with your brand."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/design/packaging/process-1.png",
            subtitle: "",
            title: "Brand Story Continuity",
            paragraph: "Packaging is a tangible extension of your brand story. Our designs align with your brand's visual identity, extending your narrative onto the packaging canvas. Consistent typography, color schemes, and imagery create a cohesive experience that reinforces brand recognition and fosters trust."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/design/packaging/process-1.png",
            subtitle: "",
            title: "Market Standout Strategy",
            paragraph: "In a crowded market, standing out is essential. Our Packaging Design service strategically positions your product. We analyze market trends, target audience preferences, and competitor landscapes to ensure your design isn't just visually appealing but also strategically positioned to grab attention and compel purchase."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/design/strategy/icon-40.png",
    title: "Strategy Design",
    description: "Charting pathways to success, our Strategy Design service formulates innovative and tailored strategies that drive growth and achieve your goals.",
    url: routes.designAndBranding + "/strategy",
    seoTitle: "Service Details",
    category: "design",
    headerImage: "/images/quasar/services/design/strategy/banner-thumb-1.png",
    serviceKey: "strategy",
    process: {
      subtitle: "",
      title: "Strategy Design",
      description: "With our Strategy Design service, we're not just creating plans; we're sculpting success. From conceptualization to execution, our strategies empower you to navigate complexities, seize opportunities, and reach new heights. We design your path to success, ensuring your journey is not only impactful but also sustainable.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/design/strategy/process-1.png",
            subtitle: "",
            title: "Vision to Reality Mapping",
            paragraph: "A successful journey starts with a clear roadmap. Our Strategy Design service begins by understanding your vision and objectives. We then craft comprehensive strategies that outline actionable steps, ensuring alignment between your goals and the path you take to achieve them."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/design/strategy/process-1.png",
            subtitle: "",
            title: "Customized Approaches",
            paragraph: "One size doesn't fit all. Our service tailors strategies to your unique needs. Whether it's market expansion, brand repositioning, or digital transformation, we delve deep into your challenges and opportunities to design strategies that leverage your strengths and address your pain points."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/design/strategy/process-1.png",
            subtitle: "",
            title: "Data-Driven Insights",
            paragraph: "In a data-rich landscape, decisions demand insights. Our Strategy Design service combines market research, consumer behavior analysis, and industry trends to inform our strategies. We help you make informed choices, ensuring your strategies are not only innovative but also grounded in data-backed intelligence."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/design/strategy/process-1.png",
            subtitle: "",
            title: "Agility and Adaptability",
            paragraph: "Change is constant, and strategies must be agile. Our designs are adaptable to evolving scenarios. We foresee potential shifts and design strategies with built-in flexibility, enabling you to pivot when needed while staying true to your long-term objectives."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/design/web/icon-40.png",
    title: "Web Design",
    description: "Crafting digital experiences that resonate, our Web Design service combines aesthetics with functionality to create websites that captivate and engage users.",
    url: routes.designAndBranding + "/web",
    seoTitle: "Service Details",
    category: "design",
    headerImage: "/images/quasar/services/design/web/banner-thumb-1.png",
    serviceKey: "web",
    process: {
      subtitle: "",
      title: "Web Design",
      description: "With our Web Design service, we're not just creating websites; we're shaping digital experiences. From aesthetics to usability, our designs empower your brand in the online world, ensuring that your website becomes a captivating platform that not only engages users but also drives results.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/design/web/process-1.png",
            subtitle: "",
            title: "Visual Storytelling",
            paragraph: "A website is your digital storefront. Our Web Design service begins with understanding your brand's essence. We then translate it into visually compelling designs that tell your story, capturing attention and leaving a lasting impact on visitors."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/design/web/process-1.png",
            subtitle: "",
            title: "Seamless User Experience",
            paragraph: "Design is not just about looks; it's about user experience. Our service prioritizes intuitive navigation and user-friendly interfaces. We create designs that guide users seamlessly through your content, ensuring every interaction is delightful and efficient."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/design/web/process-1.png",
            subtitle: "",
            title: "Responsive Adaptability",
            paragraph: "In a mobile-centric world, adaptability is key. Our Web Design service ensures your website looks and functions flawlessly across devices and screen sizes. From desktops to smartphones, we design with responsiveness in mind, guaranteeing a consistent experience for every user."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/design/web/process-1.png",
            subtitle: "",
            title: "Conversion-Oriented Focus",
            paragraph: "A website's success is measured by conversions. Our designs are strategically optimized to drive actions. Whether it's signing up, making a purchase, or contacting you, we strategically position elements to lead users towards conversion points, maximizing your website's impact on your business goals."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/marketing/architecture/icon-40.png",
    title: "Marketing Architecture",
    description: "Building success through strategic marketing, our Marketing Architecture service designs comprehensive frameworks that maximize your brand's reach, engagement, and impact",
    url: routes.marketing + "/architecture",
    seoTitle: "Service Details",
    category: "marketing",
    headerImage: "/images/quasar/services/marketing/architecture/banner-thumb-1.png",
    serviceKey: "architecture",
    process: {
      subtitle: "",
      title: "Marketing Architecture",
      description: "With our Marketing Architecture service, we're not just executing campaigns; we're designing pathways to success. From strategy formulation to tactical execution, our designs ensure that every marketing effort is aligned, impactful, and contributes to your brand's growth journey, leaving a trail of engaged audiences and tangible results.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/marketing/architecture/process-1.png",
            subtitle: "",
            title: "Strategic Blueprint",
            paragraph: "Marketing is more than tactics; it's a well-orchestrated symphony. Our Marketing Architecture service begins with crafting a strategic blueprint. We analyze your market, audience, and objectives to design a tailored marketing framework that aligns every effort towards your goals."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/marketing/architecture/process-1.png",
            subtitle: "",
            title: "Integrated Channels",
            paragraph: "Success is amplified through synergy. Our service integrates various marketing channels seamlessly. From social media to content marketing, email campaigns to influencer collaborations, we architect a holistic approach that ensures every touchpoint resonates with your brand essence."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/marketing/architecture/process-1.png",
            subtitle: "",
            title: "Data-Driven Optimization",
            paragraph: "In a data-driven landscape, we optimize for impact. Our Marketing Architecture service leverages analytics and performance metrics to refine strategies. We continuously monitor and adapt our designs, ensuring your marketing efforts are not only effective but also agile in response to changing dynamics."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/marketing/architecture/process-1.png",
            subtitle: "",
            title: "Engagement Amplification",
            paragraph: "Engagement is the cornerstone of marketing success. Our designs focus on creating meaningful connections. Whether it's crafting compelling content, sparking conversations, or nurturing customer relationships, we architect strategies that not only attract but also retain and convert audiences."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/marketing/landing-pages/icon-40.png",
    title: "Landing Pages",
    description: "Capturing attention and driving actions, our Landing Pages service designs focused web destinations that convert visitors into engaged participants.",
    url: routes.marketing + "/landing-pages",
    seoTitle: "Service Details",
    category: "marketing",
    headerImage: "/images/quasar/services/marketing/landing-pages/banner-thumb-1.png",
    serviceKey: "landing-pages",
    process: {
      subtitle: "",
      title: "Landing Pages",
      description: "With our Landing Pages service, we're not just designing web pages; we're crafting conversion points. From impactful visuals to strategic layouts, our designs maximize engagement and transform visitors into participants, driving actions that align with your business objectives and fuel your growth.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/marketing/landing-pages/process-1.png",
            subtitle: "",
            title: "Compelling First Impressions",
            paragraph: "Landing pages are the gateway to conversions. Our Landing Pages service begins by crafting designs that immediately engage visitors. We create visuals that resonate with your brand while also conveying the core message, enticing users to explore further."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/marketing/landing-pages/process-1.png",
            subtitle: "",
            title: "Clear Call to Action",
            paragraph: "Conversion is the goal, and clarity is the path. Our service strategically positions compelling calls to action. Whether it's signing up, making a purchase, or downloading content, we design landing pages with a clear and persuasive direction that guides users towards taking the desired action."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/marketing/landing-pages/process-1.png",
            subtitle: "",
            title: "Streamlined User Experience",
            paragraph: "Friction hinders conversions. Our Landing Pages service ensures seamless user experiences. We simplify navigation and eliminate distractions, creating designs that keep users focused on the key message and the intended action, maximizing the chances of conversion."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/marketing/landing-pages/process-1.png",
            subtitle: "",
            title: "Analytics-Driven Refinement",
            paragraph: "Optimization is continuous. Our designs are backed by data insights. We track user interactions and behavior, using analytics to refine landing pages for higher conversion rates. Whether it's adjusting layouts or tweaking content, our iterative approach ensures your landing pages evolve for optimal performance."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/marketing/social-media/icon-40.png",
    title: "Social Media Management",
    description: "Guiding brands through the digital social landscape, our Social Media Management service crafts strategies that foster meaningful connections and amplify your online presence.",
    url: routes.marketing + "/social-media",
    seoTitle: "Service Details",
    category: "marketing",
    headerImage: "/images/quasar/services/marketing/social-media/banner-thumb-1.png",
    serviceKey: "social-media",
    process: {
      subtitle: "",
      title: "Social Media Management",
      description: "With our Social Media Management service, we're not just managing accounts; we're nurturing connections. From strategy formulation to daily interactions, our designs ensure that your brand not only thrives in the digital social landscape but also cultivates relationships that drive brand loyalty, engagement, and growth.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/marketing/social-media/process-1.png",
            subtitle: "",
            title: "Strategic Online Persona",
            paragraph: "Social media is your brand's online persona. Our Social Media Management service starts by understanding your brand's voice and values. We then design strategies that translate your essence into captivating content, fostering a consistent and relatable digital identity."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/marketing/social-media/process-1.png",
            subtitle: "",
            title: "Engaging Content Creation",
            paragraph: "Content is the heartbeat of social media. Our service creates diverse content that resonates with your target audience. From compelling visuals to informative posts, we architect a content mix that keeps followers engaged and curious, driving interactions and building a loyal community."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/marketing/social-media/process-1.png",
            subtitle: "",
            title: "Community Interaction",
            paragraph: "Social media is a two-way street. Our designs focus on community engagement. We respond to comments, initiate conversations, and nurture relationships, creating a sense of belonging and fostering trust that transforms followers into brand advocates."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/marketing/social-media/process-1.png",
            subtitle: "",
            title: "Data-Backed Strategy Refinement",
            paragraph: "In the dynamic world of social media, data guides success. Our Social Media Management service leverages analytics to refine strategies. We track engagement metrics, audience behavior, and campaign performance, adjusting designs based on real-time insights to ensure optimal impact."
        },
      ],
    },
  },
  {
    image: "/images/quasar/services/marketing/content-creation/icon-40.png",
    title: "Content Creation",
    description: "Bringing ideas to life, our Content Creation service crafts compelling narratives and visuals that resonate with audiences across various platforms.",
    url: routes.marketing + "/content-creation",
    seoTitle: "Service Details",
    category: "marketing",
    headerImage: "/images/quasar/services/marketing/content-creation/banner-thumb-1.png",
    serviceKey: "content-creation",
    process: {
      subtitle: "",
      title: "Content Creation",
      description: "With our Content Creation service, we're not just generating content; we're igniting conversations. From concept to creation, our designs spark engagement, educate, and entertain, ensuring that every piece of content becomes a vehicle to connect with your audience, leaving a lasting impression and establishing your brand as a thought leader.",
      sections: [
        {
            id: 1,
            thumb: "/images/quasar/services/marketing/content-creation/process-1.png",
            subtitle: "",
            title: "Storytelling Mastery",
            paragraph: "Every piece of content has a story to tell. Our Content Creation service begins with understanding your brand's narrative. We then design content that communicates your message effectively, whether it's through articles, videos, or visuals, creating a seamless connection with your audience."
        },
        {
            id: 2,
            thumb: "/images/quasar/services/marketing/content-creation/process-1.png",
            subtitle: "",
            title: "Multi-Format Expertise",
            paragraph: "Content knows no boundaries. Our service excels in creating diverse formats. From written articles to engaging videos, captivating infographics to immersive podcasts, we design content that suits your audience's preferences and the platform's requirements."
        },
        {
            id: 3,
            thumb: "/images/quasar/services/marketing/content-creation/process-1.png",
            subtitle: "",
            title: "Visual Impact",
            paragraph: "Visuals speak volumes. Our Content Creation service ensures that visuals complement and enhance your content. Whether it's custom illustrations, captivating images, or dynamic graphics, we create designs that capture attention and convey your message with striking impact."
        },
        {
            id: 4,
            thumb: "/images/quasar/services/marketing/content-creation/process-1.png",
            subtitle: "",
            title: "Consistency and Flexibility",
            paragraph: "Consistency builds trust, but flexibility adapts to trends. Our designs strike a balance. We create content that aligns with your brand identity while also staying adaptable to changing dynamics, ensuring that your brand's voice remains recognizable across various topics and formats."
        },
      ],
    },
  },
];