export const featuredData = [
  {
    id: 1,
    title: 'Innovation',
    description: 'We are passionate about innovation and leveraging the latest technologies to help our clients stay ahead of the competition. We pride ourselves on being at the forefront of digital innovation, and work tirelessly to develop cutting-edge solutions that help our clients achieve their business goals',
    icon: '/images/icon/icon-24.png',
  },
  {
    id: 2,
    title: 'Partnership',
    description: 'We believe in building long-term partnerships with our clients, based on trust, communication, and collaboration. We take the time to get to know our clients and their businesses, and work closely with them to develop solutions that are tailored to their specific needs',
    icon: '/images/icon/icon-26.png',
  },
  {
    id: 3,
    title: 'Creativity',
    description: 'Our team is made up of creative thinkers who are passionate about delivering exceptional results. We bring a fresh perspective to every project we undertake, and are not afraid to think outside the box to come up with innovative solutions',
    icon: '/images/icon/icon-21.png',
  }
];