import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                    title="Privacy Policy"
                    page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Privacy policy was last updated on September 10th, 2021.</h5>
                                    </div>
                                    <p>Quasar AT ("us", "we", or "our") operates quasarat.com. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.</p>

                                    <h4>Information Collection and Use</h4>
                                    <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be
                                        used to contact or identify you. Personally, identifiable information may include but is not limited to your
                                        name ("Personal Information").</p>

                                    <h4>Log Data</h4>
                                    <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").
                                        This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type,
                                        browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those
                                        pages, and other statistics.</p>

                                    <h4>Cookies</h4>
                                    <p>Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent
                                        to your browser from a web site and stored on your computer's hard drive.</p>

                                    <h4>Security</h4>
                                    <p>The security of your Personal Information is important to us, but remember that no method of transmission over
                                        the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable
                                        means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                                    <h4>Changes to This Privacy Policy</h4>
                                    <p>This Privacy Policy is effective as of November 20th 2020 and will remain in effect except with respect to any changes in its
                                        provisions in the future, which will be in effect immediately after being posted on this page.</p>

                                    <h4>Contact Us</h4>
                                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@quasarat.com">info@quasarat.com</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;