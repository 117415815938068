import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <img className="light-version-logo" src={process.env.PUBLIC_URL + '/images/quasar-logo-bk.png'} alt="logo" />
            <img className="dark-version-logo" src={process.env.PUBLIC_URL + '/images/quasar-logo-wt.png'} alt="logo" />
            <img className="sticky-logo" src={process.env.PUBLIC_URL + '/images/quasar-logo-bk.png'} alt="logo" />
        </Link>
    )
}


export default Logo;