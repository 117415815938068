import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Terms of Use was last updated on July 7th, 2023.</h5>
                                    </div>
                                    <p>Welcome to Quasar AT's website. By accessing or using this website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree with these terms, please do not use this website.</p>

                                    <h4>1. Services</h4>
                                    <p>This website provides information about our services, including software development, 3D animation, design and branding,
                                        and marketing. The content on this website is for informational purposes only and does not constitute a contract for services.</p>

                                    <h4>2. No Account Creation</h4>
                                    <p>You are not required to create an account to access the information on this website. We do not collect personal information
                                        from users browsing our site.</p>

                                    <h4>3. Age Restrictions</h4>
                                    <p>There are no age restrictions for accessing or using this website. However, some content may be more suitable for adults.</p>

                                    <h4>4. Content Interaction</h4>
                                    <p>This website does not facilitate user-generated content or interactions between users. You may browse the content without the
                                        need for interaction.</p>

                                    <h4>5. E-commerce</h4>
                                    <p>This website does not offer e-commerce services, and no financial transactions are conducted on this platform.</p>

                                    <h4>6. Content Usage</h4>
                                    <p>The contents of this website, including text, images, and graphics, are the property of Quasar AT and are protected by
                                        copyright laws. You may use the content for personal, non-commercial purposes only. Any unauthorized use is prohibited.</p>

                                    <h4>7. No Warranties</h4>
                                    <p>This website is provided "as is" without any warranties or representations, express or implied. We make no guarantees regarding
                                        the accuracy, reliability, or completeness of the information presented on this website.</p>

                                    <h4>8. Contact Us</h4>
                                    <p>If you have any questions or concerns about these terms, please contact us at <a href="mailto:info@quasarat.com">info@quasarat.com</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;