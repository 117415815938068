import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BcrumbBannerOne from '../../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import CtaLayoutOne from '../../component/cta/CtaLayoutOne';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ServiceProp from '../../quasarComponents/service/ServiceProp';
import { services, subServices } from '../../data/quasar/services';

const SoftwareDevelopment = () => {
    const params = useParams();
    const [serviceData, setServiceData] = useState(services.find(data => data.path === window.location.pathname));
    const [servicesSections, setServicesSections] = useState(serviceData ? subServices.filter((srv) => srv.category === serviceData.category) : null);

    useEffect(() => {
        const newServiceData = services.find(data => data.path === window.location.pathname);
        const newServicesSections = newServiceData ? subServices.filter((srv) => srv.category === newServiceData.category) : null;

        setServiceData(newServiceData);
        setServicesSections(newServicesSections);

        if (!serviceData) {
            window.location.replace('/404');
        }
    }, [params]);

    if (!serviceData) {
        return null;
    }
    
    return (
        <>
            <SEO title={serviceData.seoTitle} />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title={serviceData.title}
                    paragraph={serviceData.description}
                    styleClass=""
                    mainThumb={serviceData.mainThumb}
                />
                <div className="service-scroll-navigation-area0">
                    <div className="section section-padding0 pt--70 pb--70" id="section1">
                        <div className="container">
                            <SectionTitle 
                                subtitle="Take a look to all our options"
                                title="Services we can help you with"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={servicesSections} />
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default SoftwareDevelopment;